import React, {useEffect, useState} from 'react';
import {HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import './scss/style.scss';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie';
import {useTranslation} from "react-i18next";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));


const App = () => {
  const cookies = new Cookies();
  const authCookie = cookies.get('authCookie');
  const [isAuthenticated, setIsAuthenticated] = useState(!!authCookie);
  const {i18n} = useTranslation();



  useEffect(() => {
    if (i18n.language === 'ar' || i18n.language === 'ku') {
      const cssUrl = "/css/rtl.css";
      addStyle(cssUrl);
    } else {
      removeStyle()
    }
  }, [i18n.language])

  const addStyle = url => {
    const style = document.createElement("link");
    const isThereRtl = document.getElementsByClassName('rtl_style');
    style.href = url;
    style.rel = "stylesheet";
    style.classList = "rtl_style"
    style.async = true;
    if (isThereRtl.length < 1) {
      document.head.appendChild(style);
    }
  };

  const removeStyle = () => {
    const style = document.getElementsByClassName('rtl_style');
    if (style[0]) {
      style[0].parentNode.removeChild(style[0])
    }
  }

useEffect(() => {
  if (authCookie) {
    setIsAuthenticated(true);
  } else {
    setIsAuthenticated(false)
  }
},[authCookie])


  const authenticator = (toggleAuthenticator) => {
    setIsAuthenticated(toggleAuthenticator);
  }
  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={props => <Login isAuthenticated={authenticator} {...props}/>}/>
          <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>}/>
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>}/>
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>}/>
          <Route path="/" name="Home"
                 render={props => isAuthenticated ? <TheLayout isAuthenticated={authenticator} {...props}/> : <Redirect to='/login'/>
                 }/>
          <Route path="*" name="Home" render={props => <Page404 {...props}/>}/>
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
}

export default App;
